:root {
  --foreground: #000000;
  --background-start: #f5f5f5;
  --background-end: #f5f5f5;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground: #ffffff;
    --background-start: #0b132b;
    --background-end: #000000;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  color: var(--foreground);
  background: linear-gradient(to bottom, transparent, var(--background-end))
    var(--background-start);
}
